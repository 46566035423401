<template>
  <div class="section-1 section-1-bg evo-ph-206 evo-pv-60">
    <div class="evo-head">
      <div
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="1000"
        class="d-flex justify-center mb-9"
      >
        <v-img max-width="73" max-height="73" src="@/assets/logo.png"></v-img>
        <v-img
          class="eov-logo"
          max-width="73"
          max-height="73"
          src="@/assets/about-us/EGMS-logo.png"
        ></v-img>
      </div>
      <div
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-duration="1000"
        class="evo-title evo-text-31 text-center"
      >
        {{ $t("about.subtitle_section_1_2") }}
      </div>
      <v-img
        data-aos="fade-up"
        data-aos-delay="150"
        data-aos-duration="1000"
        class="mx-auto"
        max-width="42"
        max-height="46"
        src="@/assets/about-us/idea.svg"
      ></v-img>

      <div
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-duration="1000"
        class="evo-subtitle evo-text-22 text-center"
      >
        <span class="evo-first-text">{{ $t("about.our_vision") }}</span>
        {{ $t("about.subtitle_section_1_3") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EvoAboutUsSectionOne",
};
</script>

<style lang="scss" scoped>
.section-1 {
  position: relative;
  background-color: $nav_color;
  .evo-head {
    position: relative;
    height: calc(110vh - 60px);
    color: $header_color;
  }

  .eov-logo {
    margin-left: 150px;
  }

  .evo-title {
    line-height: 61px;
    font-weight: bold;
    margin-bottom: 27px;
    white-space: pre-line;
  }

  .evo-subtitle {
    margin-top: 21px;
    font-weight: bold;
    line-height: 25px;
  }
  .evo-first-text {
    color: #ff9f00;
  }
}
</style>
