<template>
  <div class="section-5">
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-row class="evo-section5-diamond" justify="center">
          <EvoDiamond />
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-row class="evo-section5-header" justify="center">
          <v-col cols="8" sm="8" md="8">
            <div
              class="evo-section5-header-text evo-text-23 evo-mb-45"
              data-aos="fade-up"
            >
              {{ $t("about.our_business") }}
              <EvoEllipse
                class="evo-section5-ellipse"
                background="yellow"
                transform="rotate(0deg)"
                filter="blur(25px)"
                :width="$vuetify.breakpoint.xsOnly ? '60px' : '150px'"
                :height="$vuetify.breakpoint.xsOnly ? '60px' : '150px'"
              />
            </div>
            <div
              class="evo-section5-header-text evo-text-12 evo-mb-22"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              {{ $t("about.establishment") }}
            </div>
            <div
              class="
                evo-section5-desc evo-text-12 evo-opensans-regular evo-mb-28
              "
              data-aos="fade-up"
              data-aos-delay="150"
            >
              {{ $t("about.desc_section_1_1") }}
            </div>
            <v-row class="px-0 evo-mb-28">
              <v-col data-aos="fade-up" data-aos-delay="200">
                <img loading="lazy"  src="@/assets/about-us/tis.png" class="mr-16" />
                <img loading="lazy"  src="@/assets/about-us/cil.png" />
              </v-col>
            </v-row>
            <v-row class="px-0" justify="space-between">
              <v-col
                cols="12"
                sm="12"
                md="6"
                data-aos="fade-up"
                data-aos-delay="250"
              >
                <div class="evo-subheader-text evo-text-16 evo-mb-22">
                  {{ $t("about.our_service") }}
                </div>
                <div
                  class="evo-section5-desc evo-text-12 evo-opensans-regular"
                  :class="{ 'evo-px-text-9': $vuetify.breakpoint.xsOnly }"
                >
                  {{ $t("about.desc_section_1_2") }}
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                data-aos="fade-up"
                data-aos-delay="250"
              >
                <div class="evo-subheader-text evo-text-16 evo-mb-22">
                  {{ $t("about.extension") }}
                </div>
                <div
                  class="evo-section5-desc evo-text-12 evo-opensans-regular"
                  :class="{ 'evo-px-text-9': $vuetify.breakpoint.xsOnly }"
                >
                  {{ $t("about.desc_section_1_3") }}
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <EvoRect color="gradientBlue" class="evo-section5-rect1" />
  </div>
</template>

<script>
import EvoDiamond from "@/components/Diamond.vue";
import EvoEllipse from "@/components/Ellipse.vue";
import EvoRect from "@/components/Rect.vue";
export default {
  name: "EvoAboutUsSectionFive",
  components: {
    EvoDiamond,
    EvoEllipse,
    EvoRect,
  },
};
</script>

<style lang="scss" scoped>
.section-5 {
  position: relative;
  overflow: hidden;

  .evo-section5-diamond {
    padding-top: 40px;
  }

  .evo-section5-header {
    padding-top: 40px;
    .evo-section5-header-text {
      position: relative;
      font-weight: bold;
      color: $title_color;
      z-index: 1;
    }
  }

  .evo-section5-desc {
    color: #a0a3a8;
  }

  .evo-subheader-text {
    font-weight: bold;
    color: $title_color;
  }

  .evo-section5-ellipse {
    position: absolute;
    top: -55px;
    left: -15px;
    z-index: -1;
  }

  .evo-section5-rect1 {
    margin-top: 60px;
    width: 100vw;
    height: 39px;
  }
}
</style>
