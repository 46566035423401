<template>
  <div class="evo-about-us-wrapper">
    <template v-if="!$vuetify.breakpoint.mobile">
      <EvoAboutUsSectionOne />
      <EvoAboutUsSectionFive />
      <EvoAboutUsSectionFour />
    </template>
    <template v-else>
      <EvoMAboutUsSectionOne />
      <EvoMAboutUsSectionFive />
      <EvoMAboutUsSectionFour />
    </template>
  </div>
</template>

<script>
import EvoAboutUsSectionOne from '@/components/about_us_sections/Section_1.vue';
import EvoAboutUsSectionFour from '@/components/about_us_sections/Section_4.vue';
import EvoAboutUsSectionFive from '@/components/about_us_sections/Section_5.vue';
import EvoMAboutUsSectionOne from '@/mobile/components/about_us_sections/MSection_1.vue';
import EvoMAboutUsSectionFour from '@/mobile/components/about_us_sections/MSection_4.vue';
import EvoMAboutUsSectionFive from '@/mobile/components/about_us_sections/MSection_5.vue';
export default {
  name: 'EvoAboutUs',
  components: {
    EvoAboutUsSectionOne,
    EvoAboutUsSectionFour,
    EvoAboutUsSectionFive,
    EvoMAboutUsSectionOne,
    EvoMAboutUsSectionFour,
    EvoMAboutUsSectionFive
  }
};
</script>

<style lang="scss" scoped>
.evo-about-us-wrapper {
  background: $header_bg_color;
}
</style>
