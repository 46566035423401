<template>
  <div class="section-5">
    <div class="evo-section5-diamond d-flex flex-column align-center">
      <EvoDiamond />
      <div class="evo-title-text evo-text-31">
        {{ $t('about.our_business') }}
      </div>
    </div>

    <div class="evo-section5-header evo-mobile mx-auto">
      <div class="evo-section5-header-text evo-text-27 d-flex justify-space-between align-center evo-mb-30">
        <span>{{ $t('about.establishment') }}</span>
        <div class="evo-text-line"></div>
      </div>
      <div class="evo-section5-desc evo-text-22 evo-opensans-regular evo-mb-28">
        {{ $t('about.desc_section_1_1') }}
      </div>
      <div class="d-flex justify-space-around evo-mb-28">
        <img loading="lazy" src="@/assets/about-us/tis.png" />
        <img loading="lazy" src="@/assets/about-us/cil.png" />
      </div>
      <div class="evo-subheader-text evo-text-27 evo-mb-30">
        {{ $t('about.our_service') }}
      </div>
      <div class="evo-section5-desc evo-text-22 evo-opensans-regular evo-mb-28">
        {{ $t('about.desc_section_1_2') }}
      </div>
      <div class="evo-subheader-text evo-text-27 evo-mb-30">
        {{ $t('about.extension') }}
      </div>
      <div class="evo-section5-desc evo-text-22 evo-opensans-regular">
        {{ $t('about.desc_section_1_3') }}
      </div>
    </div>

    <EvoRect color="gradientBlue" class="evo-section5-rect1" />
    <EvoEllipse class="evo-section5-ellipse" background="yellow" transform="translate(-50%, -50%)" filter="blur(25px)" width="60px" height="60px" />
  </div>
</template>

<script>
import EvoDiamond from '@/components/Diamond.vue';
import EvoEllipse from '@/components/Ellipse.vue';
import EvoRect from '@/components/Rect.vue';
export default {
  name: 'EvoMAboutUsSectionFive',
  components: {
    EvoDiamond,
    EvoEllipse,
    EvoRect
  }
};
</script>

<style lang="scss" scoped>
.section-5 {
  position: relative;
  .evo-section5-diamond {
    padding-top: 40px;
  }

  .evo-title-text {
    position: relative;
    margin: 43px 0 58px;
    font-weight: bold;
    color: $title_color;
    line-height: 44px;
    z-index: 1;
  }

  .evo-section5-header {
    .evo-section5-header-text {
      font-weight: bold;
      color: $title_color;
      line-height: 37px;

      .evo-text-line {
        width: 87px;
        height: 0;
        opacity: 0.13;
        border-bottom: 2px solid #4a4f55;
      }
    }
  }

  .evo-section5-desc {
    color: #a0a3a8;
    line-height: 32px;
  }

  .evo-subheader-text {
    font-weight: bold;
    color: $title_color;
    line-height: 37px;
  }

  .evo-section5-rect1 {
    margin-top: 70px;
    width: 100vw;
    height: 33px;
  }

  .evo-section5-ellipse {
    position: absolute;
    top: 140px;
    left: 50vw;
    z-index: 0;
  }
}
</style>
