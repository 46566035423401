<template>
  <div class="section-1 section-1-bg evo-pv-60 d-flex justify-center">
    <div class="evo-head">
      <div class="logo-wrap d-flex justify-center">
        <img src="@/assets/logo.png" />
        <img src="@/assets/about-us/EGMS-logo.png" />
      </div>
      <div class="evo-title evo-text-66 text-center">
        {{ $t('about.subtitle_section_1_2') }}
      </div>
      <img class="idea-icon mx-auto" src="@/assets/about-us/idea.svg" />
      <div class="evo-subtitle evo-text-33 text-center">
        <span class="evo-first-text">{{ $t('about.our_vision') }}</span>
        {{ $t('about.subtitle_section_1_3') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EvoMAboutUsSectionOne'
};
</script>

<style lang="scss" scoped>
.section-1 {
  position: relative;
  background-color: $nav_color;
  height: 196vw;

  .evo-head {
    position: relative;
    color: $header_color;

    max-width: 630px;
  }

  .logo-wrap {
    margin-bottom: 65px;
    & > img {
      width: 107px;
      height: auto;
    }
    img + img {
      margin-left: 188px;
    }
  }

  .evo-title {
    margin-bottom: 136px;
    font-weight: bold;
    white-space: pre-line;
  }

  .idea-icon {
    display: block;
    width: 91px;
    height: auto;
  }

  .evo-subtitle {
    margin-top: 74px;
    font-weight: bold;
    line-height: 54px;
  }

  .evo-first-text {
    color: #ff9f00;
  }
}
</style>
